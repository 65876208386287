// .neumorphic-button{
//     --background: transparent;
//     --background-activated: transparent;
//     --background-hover: transparent;
//     --box-shadow:none;
//     box-shadow: var(--neumorphic-box-shadow);
//     border-radius: 32px;
//     color: var(--ion-color-medium);
//     font-size: 16px;
//     height: 64px;
//     // margin: 16px;

//     p{
//         margin: 8px;
//         line-height: 2rem;
//     }

//     ion-icon{
//         color: var(--ion-color-medium);
//     }

//     &:active{
//         box-shadow: var(--neumorphic-box-shadow-inset);
//         padding-left: 2px;
//         padding-top: 2px;
//     }

//     &.small{
//         font-size: 12px;
//         height: 40px;

//     }

//     &:hover{
//         // box-shadow: 4px 2px 16px rgba(0, 0, 0, 0.22), -4px -2px 16px rgba(255, 255, 255, 1);
//         p{
//             // text-shadow: inset 4px 2px 4px rgba(0, 0, 0, 0.12), inset -4px -2px 4px rgba(255, 255, 255, 1);
//             // font: bold 200px arial, sans-serif;
//         background-color: var(--ion-color-primary);
//         color: transparent;
//         text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.12);
//         -webkit-background-clip: text;
//             -moz-background-clip: text;
//           background-clip: text;
//         }

//         ion-icon{
//             color: var(--ion-color-secondary);
//         }

//     }

//     &.no-shadow{
//         box-shadow: none;
//         p{
//             color: var(--ion-color-secondary);
//             margin: 0;
//             line-height: 16px;
//         }
//         ion-icon{
//             color: var(--ion-color-secondary);
//         }
//     }

//     &.dark {
//         box-shadow: var(--neumorphic-box-shadow-dark);


//     }
//   }


//   .circle-icon-button{
//     width: 60px;
//     height:60px;
//     border-radius: 50%;
//     ion-icon {
//         font-size: 3rem !important;
//       }

//       ion-img{
//           max-width: 50%;
//           margin: auto;
//       }

// }


.swiper-button-next{
  color: var(--ion-color-dark);
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next{
  color: var(--ion-color-dark);

}



