h1,
h2, h4 {
  font-weight: 800;
  // font-size: 2.5rem;
  // color: var(--ion-color-dark);
  text-shadow: var(--neumorphic-text-shadow);
  outline: none;
  font-family: 'Enriqueta', serif;

  .underline {
    box-shadow: var(--neumorphic-box-shadow);
    width: 100%;
    height: 10px;
    border-radius: 16px;
    display: none;
  }
}

h1 {
  font-size: 2.5rem;
  // font-weight: 800;
}

h2 {
  font-size: 2.0rem;
  // font-weight: 800;
}

h3 {
  // font-size: 2.4rem;
  // color: var(--ion-color-dark);
  // font-weight: normal;
  // text-transform: uppercase;
  // margin-top: 0px;
  // line-height: 0.8;
  // font-weight: 800;
}

ion-card-title {
  font-size: 1.4rem;
  // text-shadow:var(--neumorphic-text-shadow);
}

ion-title, ion-list-header {
  font-family: 'Enriqueta', serif;
  // text-shadow:var(--neumorphic-text-shadow);
}

// p{
//   color: var(--ion-color-medium);
// }



@keyframes fadeInUp {
  from {
    // opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    // opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}


.tiny-text {
  font-size: 10px;
}

.header-note {
  color: var(--ion-color-medium);
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  top: -10px;
  z-index: 1000;
  margin: 0;
  margin-left: 18px;
  margin-bottom: 16px;
}

