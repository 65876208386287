// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/


.full-screen-modaL{
  --width: 100%;
  --height: 100%;
}


/** Ionic CSS Variables **/
:root {

  .swiper-pagination-bullet {
    background-color: var(--ion-color-secondary);
  }




  // html {
  //   height: 100vh;
  // }

  // body {
  //   height: 100%;
  // }

  :focus {
    outline: none;
  }

  .can-go-back {
    .toolbar-logo {
      display: none;
    }
  }


  // --ion-background-color: #ffffff;
  --ion-toolbar-background: #ffffff;
  --ion-font-family: 'Nunito', sans-serif;

  // --ion-text-color: #92949c;

  //SHADOWS

  // --neumorphic-text-shadow: 6px 2px 6px rgba(0, 0, 0, 0.04),
  // -4px -2px 4px rgba(255, 255, 255, 0.32);
  --neumorphic-box-shadow: 4px 2px 16px rgba(0, 0, 0, 0.08),
  -4px -2px 16px rgba(255, 255, 255, 0.99);
  // --neumorphic-box-shadow-dark: 4px 2px 16px rgba(0, 0, 0, 0.88),
  // -4px -2px 16px rgba(255, 255, 255, 0.08);

  --neumorphic-box-shadow-inset: inset 4px 2px 4px rgba(0, 0, 0, 0.08),
  inset -4px -2px 4px rgba(255, 255, 255, 0.4);
      --neumorphic-box-shadow-inset-dark: inset 4px 2px 16px rgba(0, 0, 0, 0.88), inset -4px -2px 16px rgba(255, 255, 255, 0.08);

  --neumorphic-svg-mask-shadow: drop-shadow(4px 2px 3px rgba(0, 0, 0, 0.08)) drop-shadow(-4px -2px 2px rgba(255, 255, 255, 0.32));
  --neumorphic-svg-mask-shadow-stronger: drop-shadow(4px 2px 3px rgba(0, 0, 0, 0.2)) drop-shadow(-4px -2px 2px rgba(255, 255, 255, 0.64));



  /** primary **/
  --ion-color-primary: #1F268E;
	--ion-color-primary-rgb: 31,38,142;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #1b217d;
	--ion-color-primary-tint: #353c99;

	--ion-color-secondary: #E35E1C;
	--ion-color-secondary-rgb: 227,94,28;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #c85319;
	--ion-color-secondary-tint: #e66e33;

	--ion-color-tertiary: #6030ff;
	--ion-color-tertiary-rgb: 96,48,255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #542ae0;
	--ion-color-tertiary-tint: #7045ff;

	--ion-color-success: #ABA321;
	--ion-color-success-rgb: 171,163,33;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #968f1d;
	--ion-color-success-tint: #b3ac37;

	--ion-color-warning: #EDCF40;
	--ion-color-warning-rgb: 237,207,64;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #d1b638;
	--ion-color-warning-tint: #efd453;

	--ion-color-danger: #F4794F;
	--ion-color-danger-rgb: 244,121,79;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 0,0,0;
	--ion-color-danger-shade: #d76a46;
	--ion-color-danger-tint: #f58661;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;



  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255,
  255,
  255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,
  0,
  0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

}

// body[data-theme="dark"]{
//   --ion-background-color: red;

// }

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

// @media (prefers-color-scheme: dark) {
/*
   * Dark Colors
   * -------------------------------------------
   */



